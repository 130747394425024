/*
-------------------------------------------------------------------------------
Vue Router
-------------------------------------------------------------------------------
Description
This file sets up the routes for the entire application.

Aliases / Shortcuts for reference:

"@" : /src
"@a": /src/assets
"@c": /src/components
"@s": /src/components/shared
"@m": /src/components/shared/mixins
"@p": /src/plugins

-------------------------------------------------------------------------------
Dependencies
-------------------------------------------------------------------------------
*/

/* Vue core modules */
import Vue from "vue";
import Router from "vue-router";
import store from "@/stores";

/* Helpers */
import auth from "@/auth";
import SyncVersion from "@/lib/VersionCheck";
import { isPathAllowed, isEnabledFeature } from "@/vuerouter/router_helpers";
import { statusClasses } from "@/utils/shared/css-status-classes";

/*
-------------------------------------------------------------------------------
Imported Routes
-------------------------------------------------------------------------------
Description
Routes that are grouped together by their feature.
-------------------------------------------------------------------------------
*/

import adminRoutes from "@/vuerouter/admin_routes";
import businesstrackerRoutes from "@/vuerouter/businesstracker_routes";
import labmacRoutes from "@/vuerouter/labmac_routes";
import marketwatchRoutes from "@/vuerouter/marketwatch_routes";
import unauthenticatedRoutes from "@/vuerouter/unauthenticated_routes";
import distributorRoutes from "@/vuerouter/distributor_routes";
import analyticsRoutes from "@/vuerouter/analytics_routes";
import partnerRoutes from "@/vuerouter/partner_routes";
import { routes as DataManagementRoutes } from "@/vuerouter/data_management";

/*
-------------------------------------------------------------------------------
Components - Farms
-------------------------------------------------------------------------------
*/


Vue.use(Router);

let routes = [
  { path: "*", component: () => import("@c/NotFound"), name: "Not Found" },
  { path: "/terms", component: () => import("@/Terms"), name: "Terms and Conditions" },
  {
    path: "/updated-terms",
    component: () => import("@/TermsNew"),
    name: "New Terms and Conditions",
  },
  {
    path: "/farm/products/view",
    component: () => import("@c/Product/ProductsView"),
    name: "Products View",
  },
  {
    path: "/farm/marketplace/requests",
    component: () => import("@c/Farm/MyRequests"),
    name: "Marketplace Requests",
  },
  {
    path: "/farm/marketplace/request/products",
    component: () => import("@c/Farm/RequestProducts"),
    name: "Marketplace Request Products",
  },
  {
    path: "/farm/marketplace/transactions",
    component: () => import("@c/Farm/OrderHistory"),
    name: "Order History",
  },
  {
    path: "/farm/marketplace/groups/suppliers",
    component: () => import("@c/Farm/Suppliers"),
    name: "Suppliers",
  },
  {
    path: "/farm/groups/users",
    component: () => import("@c/shared/users/Users"),
    name: "Users",
    meta: { tabTitleKey: "users.tabTitle" },
  },
  {
    path: "/farm/marketplace/requests/quotes",
    component: () => import("@c/Farm/Quotes"),
    name: "Marketplace Quotes",
    alias: "/requests/quotes",
  },
  {
    path: "/farm/profile",
    component: () => import("@c/Farm/FarmProfile"),
    name: "Farm Information",
    meta: { tabTitleKey: "farmProfile.tabTitle" },
  },
  {
    path: "/group/edit_group",
    component: () => import("@c/shared/EditGroup"),
    name: "Edit Group",
  },
  {
    path: "/feature/pricecheck",
    component: () => import("@c/shared/PriceCheckFeature"),
    name: "Feature Contact",
  },
  {
    path: "/feature/explorer",
    component: () => import("@c/shared/ExplorerFeature"),
    name: "Explorer Feature",
  },
  {
    path: "/feature/marketplace",
    component: () => import("@c/shared/MarketplaceFeature"),
    name: "Marketplace Feature",
  },
  {
    path: "/farm/tracker/budgets",
    component: () => import("@/pages/farm/budgets/Budgets"),
    name: "Tracker Budgets",
  },
  {
    path: "/farm/tracker/budgets/year/:year",
    component: () => import("@/pages/farm/budgets/Budgets"),
    name: "Tracker Budgets - Year",
  },
  {
    path: "/farm/programme",
    component: () => import("@/pages/farm/programmecheck/Programme"),
    name: "Programme Check",
  },
  {
    path: "/farm/programme/:year",
    component: () => import("@/pages/farm/programmecheck/Programme"),
    name: "Programme Check - Year",
  },
  {
    path: "/farm/programme/:year/crop/:crop",
    component: () => import("@/pages/farm/programmecheck/Programme"),
    name: "Programme Check - Year, Crop",
  },
  {
    path: "/farm/programme/:year/crop/:crop/variety/:variety",
    component: () => import("@/pages/farm/programmecheck/Programme"),
    name: "Programme Check - Year, Crop, Variety",
  },
  {
    path: "/farm/programme/:year/crop/:crop/variety/:variety/field/:field",
    component: () => import("@/pages/farm/programmecheck/Programme"),
    name: "Programme Check - Year, Crop, Variety, Field",
  },

  {
    path: "/farm/activities",
    component: () => import("@c/Farm/programmecheck/ProgrammeCheckActivities"),
    name: "Farm Programme Check Activities",
    alias: "/farm/data/activities",
  },
  {
    path: "/farm/data/yields",
    component: () => import("@c/Farm/programmecheck/ProgrammeCheckYields"),
    name: "Farm Programme Check Yields",
  },
  {
    path: "/feature/programmecheck",
    component: () => import("@c/shared/ProgrammeCheckFeature"),
    name: "Programme Check Feature",
  },
  {
    path: "/feature/field-by-field",
    component: () => import("@c/shared/FieldByFieldAnalyticsFeature"),
    name: "Field By Field Analytics Feature",
  },
  {
    path: "/feature/myactivities",
    component: () => import("@c/shared/ActivitiesFeature"),
    name: "My Activities Feature",
  },
  {
    path: "/farm/pricecheck",
    component: () => import("@c/shared/PriceCheckOverview"),
    name: "Price Check Overview",
  },
  {
    path: "/farm/pricecheck/season/:season",
    component: () => import("@c/shared/PriceCheckOverview"),
    name: "Price Check Overview - Season",
  },
  {
    path: "/farm/pricecheck/myprices",
    component: () => import("@c/shared/PriceCheckMain"),
    name: "Farm Price Data",
  },
  {
    path: "/farm/pricecheck/myprices/season/:season",
    component: () => import("@c/shared/PriceCheckMain"),
    name: "Farm Price Data - Season",
  },
  {
    path: "/farm/myreports",
    component: () => import("@c/shared/PriceCheckMain"),
    name: "Farm Price Reports",
  },
  {
    path: "/farm/pricecheck/mypurchases",
    component: () => import("@c/shared/PriceCheckListGroup"),
    name: "Farm Price Transactions",
    alias: "/farm/data/mypurchases",
  },
  {
    path: "/farm/data/upload",
    component: () => import("@c/Farm/Upload/UploadPage"),
    name: "Farm Upload Data",
  },

  // =================== Shared Routes ======================
  {
    path: "/product/details/:product_id",
    component: () => import("@c/shared/ProductDetails"),
    props: true,
  },
  {
    path: "/user/profile",
    component: () => import("@c/shared/Profile"),
    name: "My Profile",
    meta: { tabTitleKey: "profile.tabTitle" },
  },
  // To uncomment when https://yagro.com/en/release-notes page will be live
  // {
  //   path: "/user/release-notes",
  //   component: ReleaseNotes,
  //   name: "User Release Notes",
  //   beforeEnter() {
  //     window.open(`https://yagro.com/en/release-notes`, '_blank', 'noreferrer');
  //   },
  // },
  {
    path: "/users/edit_profile",
    component: () => import("@c/shared/EditProfile"),
    name: "Edit Profile",
  },

  // =================== Default Route ========================
  {
    path: "/",
    name: "Dashboard Home",
    redirect: () => "/dashboard",
  },
  // =================== Login Route ==========================
  {
    path: "/users/login",
    component: () => import("@c/Login"),
    name: "Login",
    meta: { tabTitleKey: "login.tabTitle" },
  },
  {
    path: "/users/login/:partner_id",
    redirect: (to) => {
      return {
        path: "/users/login",
        // eslint-disable-next-line camelcase
        query: { partner_id: to.params.partner_id },
      };
    },
  },
  {
    path: "/document/download/:docId",
    component: () => import("@c/DocDownload"),
    name: "Document Download",
    props: true,
  },
  {
    path: "/new-password",
    component: () => import("@/ForcePasswordChange"),
    name: "New Password",
    props: true,
  },
];

// =================== Feature conditional dashboard ==========================
let dashboard = {
  path: "/dashboard",
  component: () => import("@c/Dashboard"),
  name: "Dashboard",
};

if (store.getters["settings/marketplace"] === false) {
  if (store.getters["settings/explorer"]) {
    dashboard.redirect = "/farm/analytics/explorer";
  }
}

/*
-------------------------------------------------------------------------------
Add imported routes
-------------------------------------------------------------------------------
Description
Adds the routes from the imported files to the routes array.
-------------------------------------------------------------------------------
*/

routes.push(dashboard);
routes = routes.concat(adminRoutes);
routes = routes.concat(marketwatchRoutes);
routes = routes.concat(businesstrackerRoutes);
routes = routes.concat(labmacRoutes);
routes = routes.concat(distributorRoutes);
routes = routes.concat(analyticsRoutes);
routes = routes.concat(unauthenticatedRoutes);
routes = routes.concat(partnerRoutes);
routes = routes.concat(DataManagementRoutes);

/*
-------------------------------------------------------------------------------
Initialise the Router
-------------------------------------------------------------------------------
Description
Adds the routes from the imported files to the routes array.
-------------------------------------------------------------------------------
*/

function initRouter(features) {
  // ==================== Router registration ====================
  let filteredRoutes = [];
  for (const route of routes) {
    if (
      !route.meta ||
      !route.meta.requireGlobalFeature ||
      isEnabledFeature(route.meta.requireGlobalFeature, features)
    ) {
      filteredRoutes.push(route);
    }
  }

  const vueRouter = new Router({
    mode: "hash",
    routes: filteredRoutes,
    scrollBehavior(to) {
      if (to.hash) {
        const element = document.querySelector(to.hash);

        if (element) {
          element.scrollIntoView(
            {
              behavior: 'auto',
              block: 'center',
            },
          )
          element.classList.add(statusClasses.toHighlight);

          setTimeout(() => {
            element.classList.add(statusClasses.highlighted);
          }, 500);

          setTimeout(() => {
            element.classList.remove(statusClasses.highlighted);
          }, 3500);

          return { selector: to.hash };
        }
      }

    },
  });

  /* Router Guards */
  vueRouter.beforeEach(async (to, from, next) => {
    function forcePasswordChange() {
      if (store.getters["user/get_force_reset_password"]) {
        if (!isPathAllowed(to, "/new-password")) {
          next("/new-password");
          return;
        }
      }
    }

    function forceTermsAcceptance() {
      if (
        !store.getters["user/get_current_terms_accepted"] &&
        auth.user_authenticated()
      ) {
        if (!isPathAllowed(to, "/updated-terms")) {
          next("/updated-terms");
          return;
        }
      }
    }

    function checkIfUserIsAuthenticated() {
      if (to.meta.adminOnly === true) {
        if (!auth.isAdmin()) {
          next(false);
          return;
        }
      } else {
        if (store.state.user.group === undefined) {
          next(false);
        }
      }
    }

    function capturePosthogData() {
      if (store.getters["settings/env_name"] == "yag.ro") {
        const posthog = store.getters["settings/posthog"];
        if (posthog) {
          try {
            posthog.capture("$pageview", {
              // eslint-disable-next-line camelcase
              $current_url: to.fullPath,
            });
          } catch (err) {
            console.error(err);
          }
        }
      }
    }

    async function setUserChoicesFromURLParams() {
      const queriedYear = to.query.year || to.params.year;
      const queriedCrop = to.query.crop || to.params.crop;

      const dispatchPromises = [];

      if (queriedYear) {
        dispatchPromises.push(
          store.dispatch("selections/requestToSetYear", queriedYear),
        );
      }

      if (queriedCrop) {
        dispatchPromises.push(
          store.dispatch("requests/requestToSetCropById", queriedCrop),
        );
      }

      return Promise.all(dispatchPromises);
    }

    checkIfUserIsAuthenticated();
    forceTermsAcceptance();
    forcePasswordChange();
    capturePosthogData();
    SyncVersion();
    await setUserChoicesFromURLParams();
    next();
  });

  return vueRouter;
}

/*
-------------------------------------------------------------------------------
NavigationDuplicated error
-------------------------------------------------------------------------------
Description
This patches vue-router so that it no longer throws an error if it thinks the
requested route is the same as the current route.

We still need to consider other errors, so we only catch NavigationDuplicated.

This is preferred to the recommended solution of using a catch() in each
instance.

Reference
https://github.com/vuejs/vue-router/issues/2872#issuecomment-519073998
https://dev.to/bharathvajganesan/how-to-fix-navigationduplicated-vue-router-error-54ga
-------------------------------------------------------------------------------
*/

["push", "replace", "go"].forEach((method) => {
  const originalMethod = Router.prototype[method];
  Router.prototype[method] = function m(location) {
    return originalMethod.call(this, location).catch((error) => {
      if (error.name !== "NavigationDuplicated") {
        console.error(error);
      } else {
        console.info("vue-router: NavigationDuplicated");
      }
    });
  };
});

export default initRouter;
